import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import DropdownSelect from "../ui/DropdownSelect";
import CopyIcon from "../images/icons/regular/copy.svg";
import BannerAd from "../images/bechbox_generativ_ai_banner.png";
import { Link } from "react-router-dom";
import Modal from "../ui/Modal";

const fetchData = async () => {
  const response = await fetch("/data.json");
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

function PromptBuilder() {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [result, setResult] = useState("");
  const [selectedStyle, setSelectedStyle] = useState("photography");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleSelectChange = (category) => (event) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [category]: event.target.value,
    }));
  };

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalVisible(true);
    setTimeout(() => {
      setIsModalVisible(false);
    }, 2000); // 2 seconds
  };

  const handleBuildClick = () => {
    const selectedValues = Object.values(selectedOptions);
    const args = [];
    let medium = "";
    if (selectedStyle === "photography") {
      medium = "photo ";
      args.push({ "--style": "raw" });
      args.push({ "--ar": "16:9" });
    }
    if (selectedStyle === "illustration") {
      medium = "illustration ";
      args.push({ "--ar": "4:3" });
    }
    if (selectedStyle === "mixed media") {
      medium = "mixed medium photography collage ";
    }
    if (selectedStyle === "food") {
      medium = "food photography, ";
    }

    setResult(medium + selectedValues.join(", ") + " " + buildArgs(args));
  };

  const buildArgs = (argsArray) => {
    return argsArray
      .map((arg) => {
        const key = Object.keys(arg)[0];
        return `${key} ${arg[key]}`;
      })
      .join(" ");
  };

  const handleStyleButtonClick = (style) => () => {
    setSelectedStyle(style);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["jsonData"],
    queryFn: fetchData,
  });

  useEffect(() => {
    if (data) {
      setSelectedOptions({});
      const defaultValues = {};
      const styleData = data.styles[selectedStyle];
      Object.keys(styleData).forEach((key) => {
        const categoryOptions = Object.values(styleData[key]);
        if (categoryOptions.length > 0 && Array.isArray(categoryOptions[0])) {
          defaultValues[key] = categoryOptions[0][0];
        }
      });
      setSelectedOptions(defaultValues);
    }
    return () => {
      // Clean up timeout
      setIsModalVisible(false);
      clearTimeout(showModal);
    };
  }, [data, selectedStyle]);

  const handleRandomClick = () => {
    if (data && selectedStyle) {
      const styleData = data.styles[selectedStyle];
      const randomValues = {};

      Object.keys(styleData).forEach((category) => {
        const subcategories = Object.values(styleData[category]);
        const randomSubcategory =
          subcategories[Math.floor(Math.random() * subcategories.length)];
        const randomValue =
          randomSubcategory[
            Math.floor(Math.random() * randomSubcategory.length)
          ];

        randomValues[category] = randomValue;
      });

      setSelectedOptions(randomValues);
    }
  };

  const handleCopyToClipboard = () => {
    if (result) {
      navigator.clipboard.writeText(result).then(
        () => {
          showModal("Copied to clipboard");
        },
        (error) => {
          console.error("Could not copy text: ", error);
        }
      );
    }
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error fetching data: {error.message}</p>;

  return (
    <div className="App">
      <h1>The Additive Prompt Builder</h1>

      <div className="styles-container">
        {Object.keys(data.styles).map((style) => (
          <button
            key={style}
            onClick={handleStyleButtonClick(style)}
            className={`style-button ${
              style === selectedStyle ? "style-selected" : ""
            }`}
          >
            {style}
          </button>
        ))}
      </div>

      <div className="selects-container">
        {Object.keys(data.styles[selectedStyle]).map((key) => (
          <DropdownSelect
            key={key}
            label={key}
            options={data.styles[selectedStyle][key]}
            selected={selectedOptions[key] || ""}
            onChange={handleSelectChange(key)}
          />
        ))}
      </div>

      <div className="buttons">
        <button onClick={handleBuildClick}>Build</button>
        <button onClick={handleRandomClick}>Randomize</button>
      </div>

      {result && (
        <div className="result-wrapper">
          <div
            className="result"
            onClick={handleCopyToClipboard}
            title="Copy to clipboard"
          >
            <div>{result}</div>
            <div className="copy-icon">
              <img src={CopyIcon} alt="copy icon" />
            </div>
          </div>
          <div className="result-caption">
            (copy/paste the result to your favorite generative AI tool)
          </div>
        </div>
      )}
      <div className="footer">
        <Link
          to="https://learning.bechbox.com"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={BannerAd}
            alt="Generativ billedskabelse med AI"
            className="banner"
          />
        </Link>
        <div className="credit">
          Made by{" "}
          <Link to="https://www.bechbox.com/" target="_blank" rel="noreferrer">
            BechBox
          </Link>
        </div>
      </div>
      <Modal message={modalMessage} isVisible={isModalVisible} />
    </div>
  );
}

export default PromptBuilder;
