import React, { useState } from "react";
import {
  Root,
  Trigger,
  Content,
  Item,
  Label,
} from "@radix-ui/react-dropdown-menu";

import ArrowDown from "../images/icons/regular/caret-down.svg";

const DropdownSelect = ({ label, options, selected, onChange }) => {
  const [isCustom, setIsCustom] = useState(false);
  const [customValue, setCustomValue] = useState("");

  const handleDropdownChange = (value) => {
    if (value === "Custom...") {
      setIsCustom(true);
      setCustomValue("");
      onChange({ target: { value: "" } }); // Clear the value
    } else {
      setIsCustom(false);
      onChange({ target: { value } });
    }
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setCustomValue(value);
    onChange(event); // Propagate the custom value
  };

  return (
    <div className="select-wrapper">
      <label>{label}</label>
      {isCustom ? (
        <input
          value={customValue}
          onChange={handleInputChange}
          placeholder=""
        />
      ) : (
        <Root>
          <Trigger className="select">
            {selected || "Select an option"}
            <span className="arrow-down">
              <img src={ArrowDown} alt="arrow down" />
            </span>
          </Trigger>

          <Content className="dropdown-content">
            {Object.keys(options).map((category) => (
              <React.Fragment key={category}>
                {Object.keys(options).length > 1 && (
                  <Label className="group-label">{category}</Label>
                )}
                {options[category].map((option, index) => (
                  <Item
                    key={index}
                    className="dropdown-item"
                    onSelect={() => handleDropdownChange(option)}
                  >
                    {option}
                  </Item>
                ))}
              </React.Fragment>
            ))}

            <Label className="group-label">Other</Label>
            <Item
              className="dropdown-item"
              onSelect={() => handleDropdownChange("Custom...")}
            >
              Custom ... or none
            </Item>
          </Content>
        </Root>
      )}
    </div>
  );
};

export default DropdownSelect;
