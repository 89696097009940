import { Routes, Route } from "react-router-dom";
// Supports weights 100-900
import "@fontsource-variable/inter";

import "./App.scss";
import PromptBuilder from "./pages/PromptBuilder";

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<PromptBuilder />} />
    </Routes>
  );
}

export default App;
