import React from "react";

const Modal = ({ message, isVisible }) => {
  return (
    isVisible && (
      <div className="modal">
        <div className="modal-content">{message}</div>
      </div>
    )
  );
};

export default Modal;
